<template>
  <div
    class="app-background"
    :style="{ 'background-image': 'url(' + backgroundImage + ')' }"
  >
    <div v-if="!hasOrganizations">
      <div class="auth-form">
        <img alt="logo" class="logo" :src="logo" />
        <h3 class="heading">Sign in</h3>
        <a-form
          layout="vertical"
          :model="form"
          :rules="rules"
          name="login"
          class="user-form"
          @finish="onSubmit"
        >
          <div class="row">
            <div class="col-sm-12">
              <a-form-item name="email" autocomplete="off">
                <a-input v-model:value="form.email" placeholder="Enter email" />
              </a-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <a-form-item name="password" autocomplete="off">
                <a-input-password
                  v-model:value="form.password"
                  placeholder="Password"
                />
              </a-form-item>
            </div>
          </div>

          <div>
            <div class="signin-btn">
              <a-button
                type="primary"
                size="large"
                html-type="submit"
                class="btn-block"
              >
                <span>Sign in</span>
              </a-button>
            </div>

            <div class="middle">
              <div class="divider"></div>
              <div class="continue">OR</div>
              <div class="divider"></div>
            </div>
            <div class="ms-logo">
              <a-button
                style="margin-right: 105px; margin-left: 3px"
                class="btn-block"
                @click="AzureSignIn"
              >
                <img :src="mslogo" alt="" class="ms-logo" />
                <span style="font-weight: bold"
                  >Continue with Microsoft Account</span
                >
              </a-button>
            </div>
          </div>

          <!-- <GoogleLogin
            style="margin-right: 105px; margin-left: 3px; margin-top: 30px; align"
            class="w-100 d-flex retro-google-button"
            :callback="GoogleSignIn"
          /> -->

          <div class="buttons-w form-link mt-5">
            <div class="buttons-w">
              <router-link to="/forgot-password">
                <small>Forgot Password?</small>
              </router-link>
            </div>

            <div v-show="this.showVerifyRoute" class="buttons-w mt-2">
              <router-link to="/resend-verification">
                <small>Resend email Verification?</small>
              </router-link>
            </div>
            <div v-if="showModal === true">
              <div>
                <OTPModal v-if="receiveOtpOn === 'email'" @close="ModalClose" />
                <OTPVerificationModal
                  v-else-if="receiveOtpOn === 'googleAuth'"
                  modalTitle="Authenticator App"
                  :instruction="googleAuthOtpMessage"
                  @close="ModalClose"
                />
                <OTPVerificationModal
                  v-else-if="receiveOtpOn === 'sms'"
                  modalTitle="SMS"
                  :instruction="smsOtpMessage"
                  @close="ModalClose"
                />
              </div>
            </div>
          </div>
        </a-form>
      </div>
    </div>

    <div v-else class="organization-card">
      <img alt="logo" class="logo" :src="logo" />
      <h3 class="heading">Which organization do you want to access?</h3>
      <ul class="organization-list">
        <li
          v-for="organization in userOrganizations"
          :key="organization.id"
          @click="
            selectOrganization({
              organization: organization.organization,
            })
          "
        >
          {{ organization.organization }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, useStore } from 'vuex';
import mslogo from '../../assets/img/ms-logo.png';
import { useToast } from 'vue-toastification';
import OTPModal from './OTPModal.vue';
import OTPVerificationModal from './OTPVerificationModal.vue';

export default {
  setup() {
    const store = useStore();
    const rules = {
      email: [
        {
          required: true,
          type: 'email',
          trigger: 'submit',
          message: 'email is required',
        },
      ],
      password: [
        { required: true, trigger: 'submit', message: 'password is required' },
      ],
    };

    const toast = useToast();

    return {
      rules,
      logo: require('src/assets/img/assembly-cp-logo.png'),
      backgroundImage: require('src/assets/img/backgroundImage.png'),
      mslogo,
      store,
      toast,
    };
  },
  computed: {
    ...mapGetters([
      'msalInstance',
      'accessToken',
      'receiveOtpOn',
      'isOtpSetup',
      'userOrganizations',
      'email',
      'role',
    ]),
    hasOrganizations() {
      return this.userOrganizations && this.userOrganizations.length > 0;
    },
  },
  created() {
    this.createMsalInstance();
  },
  components: {
    OTPModal,
    OTPVerificationModal,
  },
  data() {
    return {
      showModal: false,
      showVerifyRoute: false,
      account: undefined,
      form: {
        email: '',
        password: '',
      },
      googleAuthOtpMessage:
        'Please use the 6-digit OTP from your authenticator app for verification.',
      smsOtpMessage:
        'Please use the 6-digit OTP sent on your mobile number for verification.',
    };
  },
  watch: {
    userOrganizations: {
      deep: true,
      handler() {
        this.showModal = false;
      },
    },
  },
  methods: {
    ...mapActions([
      'userLogin',
      'createMsalInstance',
      'azureSignIn',
      'googleSignIn',
      'azureSignUp',
      'userLogout',
      'clearState',
      'selectOrganization',
    ]),
    async onSubmit() {
      let UserInfo = await this.userLogin({
        email: this.form.email,
        password: this.form.password,
      });

      if (UserInfo && UserInfo.require_otp) {
        this.showModal = true;
      }
      this.showVerifyRoute = true;
    },
    ModalClose() {
      this.clearState();
      this.showModal = false;
    },
    async AzureSignIn() {
      await this.msalInstance
        .loginPopup({
          scopes: ['openid', 'profile', 'User.Read', 'email'],
          prompt: 'select_account',
        })
        .then((res) => {
          const myAccounts = this.msalInstance.getAllAccounts();
          const payload = {
            access_token: res.accessToken,
          };
          this.account = myAccounts[0];
          this.msalInstance.setActiveAccount(this.account);
          this.azureSignIn(payload);
        })
        .catch((error) => {
          console.error(`error during authentication: ${error}`);
          this.store.dispatch('clearState');
        });
    },
    GoogleSignIn(response) {
      const payload = {
        access_token: response.credential,
      };
      this.googleSignIn(payload);
    },
  },
};
</script>

<style scoped>
.container {
  height: 100vh;
  overflow: hidden;
}

.auth-form {
  max-width: 450px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 16px;
  margin: auto;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  opacity: 0.9;
}

.logo {
  width: 250px;
  height: 60px;
  margin-bottom: 10px;
}
.ms-logo {
  height: 15px;
  margin-bottom: 3px;
  margin-right: 8px;
  align-content: center;
}
.signin-btn {
  margin-left: 0px;
}
.heading {
  font-size: 1.4rem;
  margin-bottom: 14px;
}
.user-form {
  width: 80%;
  margin-top: 20px;
}

.form-link {
  text-align: center;
  margin: 24px 0;
}
.form-link a {
  text-decoration: none;
  font-size: 16px;
}
.form-link a:hover {
  text-decoration: underline;
}
.middle {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0;
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.continue {
  box-sizing: border-box;
  margin-left: 5px;
  margin-right: 5px;
  min-width: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #707a8a;
}
.divider {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  height: 1px;
  flex: 1;
  background-color: #eaecef;
}

.app-background {
  height: 100vh;
  background-size: cover;
  position: relative;
  background-position: center;
}

.app-background::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.app-background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  position: relative;
  background-position: center;
}
.organization-card {
  width: 450px;
  margin: auto;
  text-align: center;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 16px;
  padding: 20px 40px;
  opacity: 0.95; /* Slightly more opaque */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transitions for hover effect */
}

.organization-card:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 20px; /* Darker shadow on hover */
}

.heading {
  font-size: 1.2rem; /* Reduced font size for the heading */
  margin-bottom: 20px; /* Space between heading and list */
}

.organization-list {
  list-style-type: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  max-height: 400px; /* Set a max height for the list */
  overflow-y: auto; /* Enable vertical scroll if content exceeds max height */
  overflow-x: hidden; /* Hide horizontal scrollbar if it appears */
  border-radius: 8px; /* Rounded corners for list */
  padding-right: 10px; /* Space for scrollbar */
}

.organization-list li {
  background-color: #f0f4f8; /* Light background for list items */
  border-radius: 8px; /* Rounded corners */
  padding: 12px 20px; /* Increased padding for comfort */
  margin: 10px 0; /* Space between items */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover effect */
  cursor: pointer; /* Change cursor on hover */
  font-size: 1.1rem; /* Slightly larger font for better readability */
}

.organization-list li:hover {
  background-color: #e0e7ee; /* Darker shade on hover */
  transform: scale(1.02); /* Slight scale effect on hover */
}
</style>
<style>
.retro-google-button iframe,
.retro-google-button iframe .haAclf {
  width: 100% !important;
}

:deep(.haAclf) {
  width: 100% !important;
}
</style>
