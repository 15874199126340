<template>
  <div>
    <a-modal
      :title="`Verify OTP - ${modalTitle}`"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      :mask-closable="false"
      @ok="handleOk"
      @cancel="$emit('close')"
      okText="Verify"
      cancelText="Close"
      class="otp-authentication-modal"
    >
      <div class="otp-instruction">
        <p>{{ instruction }}</p>
      </div>

      <OTPInput @update-otp-value="updateOtpValue" />
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import OTPInput from './OTPInput.vue';
import { useToast } from 'vue-toastification';
import AuthService from '@/services/auth';

export default defineComponent({
  components: {
    OTPInput,
  },
  props: ['modalTitle', 'instruction'],
  emits: ['close'],
  data() {
    return {
      visible: true,
      otpValue: '',
      confirmLoading: false,
      toast: null,
      googleAuthOtpMessage:
        'Please use OTP from your authenticator app for verification.',
    };
  },
  computed: {
    ...mapGetters(['receiveOtpOn']),
  },
  async mounted() {
    this.toast = useToast();
    console.log("this.receiveOtpOn", this.receiveOtpOn)

    if (this.receiveOtpOn === 'sms') {
        await AuthService.sendOTP();
    }
    this.toast.success(this.instruction);
  },
  methods: {
    ...mapActions(['verifyOTP']),
    async handleOk() {
      this.confirmLoading = true;
      await this.verifyOTP({ OTP: this.otpValue });
      this.confirmLoading = false;
    },
    updateOtpValue(otpValue) {
      this.otpValue = otpValue;
    },
  },
});
</script>

<style scoped>
.otp-instruction {
  text-align: center;
  margin-bottom: 20px;
}
</style>

<style>
.qr-modal .ant-modal-header {
  background-color: #1890ff;
}
.qr-modal .ant-modal-title {
  color: white;
  font-weight: bold;
}
</style>
